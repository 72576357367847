
.container-box {
  box-sizing: border-box;
  font-family: PingFang SC-Medium, PingFang SC;
  background: #fff;
  border-radius: 10px;
  height: 100%;
  padding: 30px;
  .table-box {
    height: calc(100% - 40px);
    overflow-y: auto;
  }
}
